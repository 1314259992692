:root {
  --bg-color: rgb(45, 85, 57);
  --terminal-bg-color: #373737;
  --main-text-color: #fff;
  --t-font-size-lg: 1rem;
  --c-font-size-lg: 1.4rem;
  --t-font-size-md: 1rem;
  --c-font-size-md: 1.2rem;
  --t-font-size-sm: .8rem;
  --c-font-size-sm: .9rem;
  --t-font-size-xs: .7rem;
}
body, html, #root {
  background-color: var(--bg-color);
  color: var(--main-text-color);
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.command-container {
  color: var(--main-text-color);
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  font-size: var(--c-font-size-lg);
  padding: 10px 5px 10px 5px;
}
.command-btn {
  cursor: pointer;
  margin-right: 2rem;
}
.center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-flow: row wrap;
}
.container {
  margin-left: 10px;
  margin-right: 10px;
  width: 900px;
}
.terminal {
  background-color: var(--terminal-bg-color);
  border-radius: 5px;
  font-size: var(--t-font-size-lg);
  word-break: break-all;
}
.terminal-body {
  border-radius: 5px;
  padding: 5px;
}
.terminal-content-wrapper {  
  overflow-y: auto;
  height: 450px;
}
.cursor-wrapper {
  display: inline-table;
  vertical-align: middle;
  margin-left: 1px;
}

.cursor {
  display: table-cell;
  background-color: var(--main-text-color);
  height: .9rem;
  width: .5rem;
}

@media (max-width: 900px){
  .command-container {
    font-size: var(--c-font-size-md);
  }
  .terminal {
    font-size: var(--t-font-size-md);
  }
  .command-btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media (max-width: 700px){
  .command-container {
    font-size: var(--c-font-size-sm);
  }
  .terminal {
    font-size: var(--t-font-size-sm);
  }
  .cursor {
    height: .75rem;
    width: .38rem;
  }
}
@media (max-width: 567px){
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh; 

  }
  .terminal {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-grow: 2;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
   
  }

  .terminal-body {
    flex-grow: 1;
    overflow: hidden;
    
  }
  .terminal-content-wrapper {
    height: 100%;

  }
  .command-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.1rem;
    padding: 0;
  }
  .command-btn {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}
@media (max-width: 392px) {
  .terminal {
    font-size: var(--t-font-size-xs);
  }
  .cursor {
    height: .65rem;
    width: .32rem;
  }
} 
.fade-in {
	opacity: 1;
	animation-name: fadein;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: blinker 1s step-start infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.terminal-header {
  padding: 0;
  margin: 0;
  height: 32px;
  border-radius: 5px 5px 0 0;
  background-color: #555;
  flex-shrink: 0;
}
.content-command {
  padding-top: 10px;
  display: block;

}
span.user-color {
  color: yellow;
}

span.dir-color{
  color: greenyellow;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  border: 8px #373737 solid;
  background-clip: padding-box;
  background: #555;
  border-radius: 10px;

}
body::-webkit-scrollbar-thumb {
  border: 7px var(--bg-color) solid;
  background-clip: padding-box;
  background: #555;
  border-radius: 10px;

}

.terminal-link {
  color: lightblue
}

.json-key {
  color: cornflowerblue;
}

.prompt {
  margin-left: .6rem;
  margin-right: .6rem;
}
a {

  word-break: break-all;
}